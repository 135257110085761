import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from './firebaseConfig'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userProfile: {}
    },
    mutations: {
        setUserProfile(state, val) {
            state.userProfile = val
        }
    },
    getters: {
        authenticated: state => {
            return Object.keys(state.userProfile).length != 0       
        },
        email: state => {
            return state.userProfile.email;
        },
        user: state => {
            return state.userProfile;
        }
        
    },
    actions: {
        async login({ dispatch }, form) {
            const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
            console.log(user.uid)
            console.log(user.email) 
            dispatch('fetchUserProfile', user)
            return user
        },
        
        async register({ dispatch }, form) {
            console.log("registering " + form.email + form.password)
            const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
            dispatch('fetchUserProfile', user)
            return user
        },

        async getIdToken({ getters }) {
            let user = getters.user;
            const idToken = await user.getIdToken()
            return idToken
        },

        async getUserAddress({ getters }) {
            let user = getters.user;
            let token = await this.dispatch('getIdToken')
            console.log('Token')
            console.log(token)
            const response = await axios.get(`http://127.0.0.1:3000/api/v1/users/${user.uid}/address`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response
        },

        async fetchUserProfile({ commit }, user){
            const userProfile = user
            console.log(userProfile)
            commit('setUserProfile', user)
        }
        
    },
    
    
})