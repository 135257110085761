<template>
  <v-app>
    <Authentication v-if="!['Splash'].includes($route.name)" />
    <AppBar v-if="!['Splash'].includes($route.name)" />
    <SideNav v-if="!['Splash'].includes($route.name)" />
    <BottomNav v-if="!['Splash'].includes($route.name)" />

    <v-main>
        <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Authentication from './components/Authentication/Authentication';
import SideNav from './components/Navigation/SideNav';
import AppBar from './components/Navigation/AppBar';
import BottomNav from './components/Navigation/BottomNav';

export default {
  name: 'App',

  components: {
    Authentication,
    SideNav,
    BottomNav,
    AppBar,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
#app {
    background-color: #0B0B0B;

}
</style>