<template>
    <v-container>
    <v-snackbar
        v-if="!authenticated"
        :timeout="-1"
        :value="true"
        fixed
        :bottom="!$vuetify.breakpoint.mobile"
        :top="$vuetify.breakpoint.mobile"
        color="error"
        elevation="24"
      >

        You are not logged in
      <template v-slot:action="{ attrs }">
        <v-btn @click="register_overlay = true;login_overlay = false" v-bind="attrs" elevation=0 class="error primary--text text--lighten-2">Register</v-btn>         <v-btn v-bind="attrs" @click="login_overlay = true; register_overlay = false;" elevation=0 class="error primary--text text--lighten-2">Login</v-btn>

      </template>
      </v-snackbar>
     <v-overlay
          opacity="0.8"
          :value="register_overlay"
          color="background"
        >
        <v-card class="component1">
            <v-card-title>
                <v-row no-gutters>
                    <v-col class="headline" lg=6>
                        Register
                    </v-col>
                    <v-col align="right" lg=6>
                        <v-btn @click="register_overlay = false;" icon depressed class="component1 headline"> <v-icon>mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-form v-model="registerForm.valid" ref="registerForm" id="registerForm" @submit.prevent="submit">
            <v-card-text>
                    <v-text-field class="component1" :rules="[rules.required, rules.email]" v-model="registerForm.email" id="email" label="Email"
>                       
                    </v-text-field>
                    <v-text-field class="component1" type="password" :rules="[rules.required, rules.counter]" v-model="registerForm.password" id="password" label="Password"
>                       
                    </v-text-field>
                    <v-checkbox :rules="[rules.required]" label="I agree to the terms & conditions">
                    </v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col align="center" lg=12>
                <v-btn
                :disabled="!registerForm.valid"
                color="secondary black--text"
                @click="register()"
                >
                Register
            </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-form>

        </v-card>
    </v-overlay>
     <v-overlay
          opacity="0.8"
          :value="login_overlay"
          color="background"
        >
        <v-card class="component1">
            <v-card-title>
                <v-row no-gutters>
                     <v-col class="headline" lg=6>
                        Login
                    </v-col>
                    <v-col align="right" lg=6>
                        <v-btn @click="login_overlay = false;" icon depressed class="component1 headline"> <v-icon>mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-form v-model="loginForm.valid" ref="loginForm" id="loginForm" @submit.prevent="submit">
            <v-card-text>
                    <v-text-field class="component1" :rules="[rules.required, rules.email]" v-model="loginForm.email" id="email" label="Email"
>                       
                    </v-text-field>
                    <v-text-field class="component1" type="password" :rules="[rules.required]" v-model="loginForm.password" id="password" label="Password"
>                       
                    </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col v-bind="loginForm" class="error--text" align="center" lg=12>
                        {{ loginForm.error }}
                    </v-col>
                    <v-col align="center" lg=12>
                <v-btn
                :disabled="!loginForm.valid"
                color="secondary black--text"
                @click="login()"
                >
                Login
            </v-btn>
        
                    </v-col>
                </v-row>
            </v-card-actions>
                    </v-form>

        </v-card>
    </v-overlay>
    </v-container>
</template>


<script>
export default {
    data() {
        return {
            register_overlay: false,
            login_overlay: false,
            registerForm: {
                valid: false,
                email: '',
                password: '',
                error: ''
            },
            loginForm: {
                valid: false,
                email: '',
                password: '',
                error: ''
            },
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length >= 8 || 'Must be 8 characters or more.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
        }
    },

    computed: {

        authenticated: function() {
            return this.$store.getters.authenticated
        }

    },

    methods: {
        async register() {
            let result = await this.$store.dispatch('register', {
                email: this.registerForm.email,
                password: this.registerForm.password
            }).catch(() => {
                this.registerForm.error = "Something went wrong. Please try again later"
            })

            if (result) {
                console.log(result)
                console.log("Registration succeeded")
                this.register_overlay = false
            }
        },
        async login() {
            let result = await this.$store.dispatch('login', {
                email: this.loginForm.email,
                password: this.loginForm.password
                }).catch((err) => {
                    console.log(err)
                if(err.code == "auth/wrong-password"){
                    console.log(this)
                    this.loginForm.error = "Password Incorrect"
                    console.log(this.loginForm.error)
                }
                else if(err.code == "auth/too-many-requests"){
                    console.log(this)

                    this.loginForm.error = "Account Temporarily Locked"
                    console.log(this.loginForm.error)
                }          
                })

                let id_token = await this.$store.dispatch('getIdToken')
                console.log(id_token)
                let address = await this.$store.dispatch('getUserAddress')
                console.log(address)
            if (result) {
                console.log("Login succeeded")
                this.login_overlay = false
            }
        }
    }
}
</script>