import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router/router';
import VueApexCharts from 'vue-apexcharts';
import Cryptoicon from 'vue-cryptoicon';
import icon from 'vue-cryptoicon/src/icons';
import { auth } from './firebaseConfig';
import store from './store';
import Particles from "particles.vue";

Vue.use(Particles);

Cryptoicon.add(icon);
Vue.use(Cryptoicon);

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

let app

auth.onAuthStateChanged(() => {
  if (!app) { app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
  }
})
