import firebase from "firebase"
import 'firebase/auth'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyBHFYaXzX-pHwZ8w4U85ZEn1slUWXT0T7U",
  authDomain: "argon-299216.firebaseapp.com",
  projectId: "argon-299216",
  storageBucket: "argon-299216.appspot.com",
  messagingSenderId: "659597261336",
  appId: "1:659597261336:web:5a7d73b746dd6a9c8ae16d",
  measurementId: "G-5DXX01K0J0"
}

firebase.initializeApp(firebaseConfig)

// utils
const auth = firebase.auth()



// export utils/refs
export {
  auth
}