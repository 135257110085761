<template>
        <v-navigation-drawer
      app
      class="pt-4"
      color="component1"
      expand-on-hover
    >

    <v-list-item dense>
        <v-list-item-avatar> </v-list-item-avatar>
        <v-list-item-title>
            <router-link to="/">
            <v-img to="/" class="mr-15" src="../../assets/argon1.png" max-height="100%"/>
            </router-link>
        </v-list-item-title>
    </v-list-item>
          <v-divider></v-divider>

    <v-list-item class="profit--text" link to="/home">
          <v-list-item-icon>
              <v-icon class="gradient">mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
              Dashboard
          </v-list-item-title>

    </v-list-item>
    <v-list-item class="profit--text" link to="/assets">
          <v-list-item-icon>
              <v-icon class="gradient">mdi-rhombus-split</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
              Assets
          </v-list-item-title>
    </v-list-item>
    <v-list-item class="profit--text" link to="/lending">
          <v-list-item-icon>
              <v-icon class="gradient">mdi-cash</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
              Lending
          </v-list-item-title>
    </v-list-item>
    <v-list-item class="profit--text" link to="/wallet">
          <v-list-item-icon>
              <v-icon class="gradient">mdi-wallet</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
              Wallet
          </v-list-item-title>
    </v-list-item>
    <template v-slot:append>
<v-list-item link to="/faq">
          <v-list-item-icon>
              <v-icon class="gradient">mdi-help</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
              FAQ
          </v-list-item-title>
    </v-list-item>
<v-list-item link>
          <v-list-item-icon>
              <v-icon class="gradient">mdi-mail</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
              Contact Us
          </v-list-item-title>
    </v-list-item>
 </template>
    </v-navigation-drawer>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>