import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        dark: true,
        themes: {
            dark: {
                background: '#0B0B0B',
                primary: '#0EF9AE',
                secondary: '#FAF595',
                accent: '',
                error: '#D14242',
                lightError: '#D8566B',
                info: '',
                success: '',
                warning: '',
                component1: '#121212',
                profit: '#0EF9AE'
            }
        }
    
    }
});
