import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'Splash',
      component: () => import('../views/pages/Splash.vue')
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import('../views/pages/Home.vue')
    },
    {
        path: '/lending',
        name: 'Lending',
        component: () => import('../views/pages/Lending.vue')
      },
      {
        path: '/assets',
        name: 'Assets',
        component: () => import('../views/pages/Assets.vue')
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('../views/pages/Wallet.vue')
      },
      {
        path: '/projects/:project',
        name: 'Project',
        component: () => import('../views/pages/ProjectPage.vue')
      },
      {
        path: '/splash',
        name: 'Splash',
        component: () => import ('../views/pages/Splash.vue')
      }


]
  
  const router = new VueRouter({
    mode: 'history',
    routes: routes
  })
  
export default router