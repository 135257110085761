<template>
        <v-app-bar app fixed dense class="component1" elevate-on-scroll>
              <span class="headline"> {{ this.$route.name }} </span>
              <v-spacer></v-spacer>
              <span class="headline-1">Balance: $30.25 </span>        
              <v-icon class="ml-15">mdi-magnify</v-icon> 
              <v-icon class="ml-3">mdi-bell</v-icon>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on"><v-icon class="mx-3" >mdi-account</v-icon></span>
                </template>
                <v-card>
                    <v-card-title>
                        <v-row no-gutters>
                            <v-col v-bind="email" lg=12>
                                {{email}}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                    <v-row>
                        <v-col class="headline" lg=12>
                            Transactions
                        </v-col>
                        <v-col lg=12>
                            Referrals
                        </v-col>
                        <v-col lg=12>
                            <hr>
                        </v-col>
                        <v-col lg=12>
                            Sign Out
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>
              </v-menu>
    </v-app-bar>
</template>


<script>
export default {
    
    data() {
        return {

        }
    },

    computed: {
        email: function() {
            return this.$store.getters.email
        }
    }
    

}
</script>